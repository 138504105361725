<!--
 * @Author: your name
 * @Date: 2022-04-01 10:56:16
 * @LastEditTime: 2022-04-16 10:47:01
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\views\order\index.vue
-->
<!--
 * @Author: your name
 * @Date: 2022-04-01 10:49:26
 * @LastEditTime: 2022-04-01 10:50:38
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\views\guide\index.vue
-->
<template>
  <div class="orderbox">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
      <div class="order" v-for="(item,index) in list" :key="index"> 
        <div class="order-box">
            <!-- <img :src="item.pack_img" alt="" class="left"> -->
            <div class="left">
               <div class="info">
                  <p class="num" :style="{color:item.type==4?'#4a4545':'#ffffff'}">{{Math.trunc(item.package_price)}}元{{item.total_flow/1024}}G</p>
                  <p style="color:#967d38;" class="" v-if="item.type == 4">加油包</p>
                  <p style="color:#9ed7ff;" v-if="item.type==2||item.type ==1">畅享套餐</p>
               </div>
               <img src="@/assets/images/order/data.png" alt="" v-if="item.type == 4">
               <img src="@/assets/images/order/play.png" alt="" v-if="item.type==2||item.type ==1">
            </div>
            <div class="right">
              <div class="right-left">
                <p class="pone">ID:{{item.iccid}}</p>
                <p class="ptwo">订购套餐</p>
                <p class="pthree">购买时间: {{item.pay_newtime}}</p>
              </div>
              <div class="right-right">
                <p class="ponew"><span class="span">￥</span>{{ item.package_price}}</p>
                <p class="ptwo">{{item.pay_type == 1?"微信支付":item.pay_type == 2?'余额支付':item.pay_type == 3?'优惠券支付':'支付宝支付'}}</p>
              </div>
            </div>
          </div>
        </div>
      </van-list>
  </div>
</template>
<script>
export default {
  data() {
    return{
      loading: false, // 开始加载
      finished: false, // 全部加载完毕
      page:1, // 请求第几页的
      limit:10, // 一页几条
      list:[],  // 套餐列表
    }
  },
  mounted(){
  },
  methods:{
    onLoad(){
      this.getList()
    },
    timestampToTime(shijian) {
      var date = new Date(shijian)
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ''
      return Y + M + D
    },
    getList(){
    let data ={
      iccid:sessionStorage.getItem("iccid"),
      page:this.page,
      limit:this.limit
    }
    this.getorderList({data}).then(res =>{
      if(res.data.code == 1){
        if(this.page == res.data.pagecount){
          this.list = [...this.list,...res.data.list]
          this.list.forEach(item =>{
            item.pay_newtime = this.timestampToTime(item.pay_time*1000)
          })
          this.loading = false
          this.finished = true
          this.text = "真的没有了~"
        }else{
          this.list = [...this.list,...res.data.list]
          this.list.forEach(item =>{
            item.pay_newtime = item.pay_newtime = this.timestampToTime(item.pay_time*1000)
          })
          this.loading = false
          this.page ++
        }
      }else{
        if(res.data.code == 0 ){
          this.loading = false
          this.finished = true
          this.text = "真的没有了~"
        }
      }
    })
  }
  },
  beforeCreate () {
    // JS原生设置当前背景颜色
    document.querySelector('body').setAttribute('style', 'background-color:#f5f5f5')
  },
  // 获取套餐列表详情
}
</script>
<style lang="less" scoped>
.orderbox{
  padding-bottom: 60px;
  overflow: hidden;
  .order{
  width: 100%;
  height: 100px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  .order-box{
    width: 95%;
    height: 70px;
    display: flex;
    // justify-content: space-between;
    .left{
      position: relative;
      width: 120px;
      height: 66px;
      border-radius: 6px;
      // background-color: #1a6af1;
      img {
        width: 100%;
        height: 100%;
      }
      .info {
        width: 100%;
        height: 100%;
        position: absolute;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 18px;
        font-weight: 700;
        .num {
          margin-bottom: 5px;
        }
      }
    }
    .right{
      width: 70%;
      // height: 100px;
      display: flex;
      justify-content: space-between;
      margin-left: 10px;
      .right-left{
        // margin-left: 10px;
        // margin-right: 10px;
        .pone{
          font-size: 12px;
          color: #2d3036;
          font-weight: 600;
          margin-top: 6px;
        }
        .ptwo{
          color: #1a6af1;
          font-weight: 600;
          margin: 8px 0 8px 0px;
        }
        .pthree{
          color: #969aa1;
        }
      }
      .right-right{
        flex: 1;
        .ponew{
          text-align: right;
          color: #2d3036;
          font-size: 16px;
          font-weight: 600;
          margin-top: 10px;
          margin-bottom: 5px;
          .span{
            font-size: 12px;
          }
        }
        .ptwo{
          font-size: 12px;
          color: #e0a403;
          transform: scale(0.8);
          font-weight: 600;
          text-align: right;
        }
      }
    }
  }
}
}
</style>